<template>
  <main class="management-wrapper">
    <section class="width100 flex justify-space-between filters-wrapper">
      <div class="width100 flex justify-start gap8 squads-wrapper">
        <div
          v-for="(s, index) in squads"
          :key="s"
          :style="`filter: hue-rotate(calc(${index} * 10deg));`"
        >
          <p class="-xstb squad">{{ s.name }}</p>
        </div>
      </div>
      <div class="search-wrapper"><BaseSearchBar /></div>
    </section>

    <section class="width100 flex gap16 u-cards-wrapper">
      <div v-for="m in members" :key="m" class="u-card">
        <img
          :src="m.picturePath"
          :alt="`${m.first_name} ${m.last_name}`"
          v-if="m.picturePath"
        />
        <div v-else class="img-ph">
          <p class="-xxxltb">{{ getFirstLetter(m.first_name) }}</p>
          <p class="-xxxltb">{{ getFirstLetter(m.last_name) }}</p>
        </div>
        <a href="#" class="-xstb profile-link" @click="getUserProfile"
          >View Profile</a
        >
        <div class="u-info">
          <p class="-ntb u-name">{{ m.first_name }} {{ m.last_name }}</p>
          <p class="-xstb u-role">{{ m.orgRole }}</p>
          <a :href="`mailto:${m.email}`" class="-xstb email">{{ m.email }}</a>
        </div>
      </div>
    </section>
  </main>
  <section class="btns-container">
    <BaseCancelSave />
  </section>
</template>

<script>
import BaseCancelSave from "../../utils/BaseCancelSave.vue";
import BaseSearchBar from "../../utils/BaseSearchBar.vue";
export default {
  components: {
    BaseCancelSave,
    BaseSearchBar,
  },
  data() {
    return {
      //DUMMY DATA
      squads: [{ name: "TEAM1" }, { name: "TEAM2" }, { name: "TEAM3" }],
      members: [
        {
          first_name: "Andrew",
          last_name: "Armstrong",
          email: "aarmstrong@ms4aeco.com",
          orgRole: "CEO",
          picturePath: require("../../../assets/temp/admin.png"),
        },
        {
          first_name: "Travis",
          last_name: "Chambers",
          email: "tchambers@ms4aeco.com",
          orgRole: "Architect",
          picturePath: require("../../../assets/temp/users/bcaires.png"),
        },
        {
          first_name: "Mark",
          last_name: "Green",
          email: "mgreen@ms4aeco.com",
          orgRole: "Civil Engineer",
          picturePath: require("../../../assets/temp/users/jribeiro.png"),
        },
        {
          first_name: "Sophia",
          last_name: "Holland",
          email: "aarmstrong@ms4aeco.com",
          orgRole: "Structural Engineer",
          picturePath: require("../../../assets/temp/users/imacena.png"),
        },
        {
          first_name: "Alan",
          last_name: "Davis",
          email: "adavis@ms4aeco.com",
          orgRole: "Structural Engineer",
          picturePath: null,
        },
        {
          first_name: "Mark",
          last_name: "Thompson",
          email: "mthimpson@ms4aeco.com",
          orgRole: "Mechanical Engineer",
          picturePath: require("../../../assets/temp/users/jaleixo.png"),
        },
        {
          first_name: "Robert",
          last_name: "Hunt",
          email: "rhunt@ms4aeco.com",
          orgRole: "Civil Engineer",
          picturePath: require("../../../assets/temp/users/dfrancisco.png"),
        },
      ],
    };
  },
  methods: {
    getUserProfile() {
      alert("Not enough permissions to view this profile");
    },
    getFirstLetter(passedString) {
      return passedString.charAt(0);
    },
  },
};
</script>

<style scoped>
.management-wrapper {
  width: 100%;
}
.filters-wrapper {
  /* height: 40px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.squads-wrapper {
  width: calc(100% - 256px);
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.squad {
  background-color: var(--secondary);
  color: var(--white1);
  padding-block: 4px;
  padding-inline: 8px;
  border-radius: 16px;
  user-select: none;
}
.search-wrapper {
  width: 256px;
  height: 40px;
  padding-bottom: 8px;
}
.u-cards-wrapper {
  --card-width: 256px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--card-width));
  justify-content: center;
  padding-top: 1rem;
}
.u-card {
  /* border: 1px solid blue; */
  width: var(--card-width);
  height: 320px;
  position: relative;
  overflow: hidden;
  padding: 4px;
  display: flex;
  justify-content: center;
}
.u-card > .profile-link {
  position: absolute;
  text-decoration: none;
  color: transparent;
  align-self: top;
  margin-top: 35%;
}
.u-card:hover > .profile-link {
  color: var(--white2);
  background-image: linear-gradient(var(--white2) 0%, var(--white2) 100%);
  background-position: right -100% bottom 0;
  background-size: 200% 2px;
  background-repeat: no-repeat;
  padding-bottom: 2px;
}
.u-card > .profile-link:hover {
  color: var(--white1);
  background-image: linear-gradient(var(--white1) 0%, var(--white1) 100%);
  background-position: left -110% bottom 0;
  transition: background-position 800ms ease;
}
.u-card > img,
.u-card > .img-ph {
  width: 100%;
  height: 80%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px;
  transition: 800ms ease;
}
.u-card > .img-ph {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background: var(--focus3);
  user-select: none;
  border: 4px solid var(--white1);
}
.u-card > .u-info {
  position: absolute;
  z-index: 1;
  background: var(--white1);
  padding: 8px;
  bottom: 10%;
  min-width: 80%;
  height: max-content;
  transition: 600ms ease;
}
.u-card:hover > img,
.u-card:hover > .img-ph,
.u-card:hover > .u-info {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}
.u-card:hover > img {
  transform: translateY(8px);
  filter: brightness(0.5);
}
.u-card:hover > .img-ph {
  transform: translateY(8px);
  background: var(--focus2);
}
.u-card:hover > .u-info {
  transform: translateY(-16px);
}
.u-name {
  font-family: "Montserrat-Black";
  padding-bottom: 4px;
  color: var(--primary);
}
.u-role {
  font-family: "Montserrat-ExtraBold";
}
.email {
  text-decoration: none;
  color: inherit;
  background-image: linear-gradient(var(--primary) 0%, var(--primary) 100%);
  background-position: 0 100%;
  background-size: 0% 1px;
  background-repeat: no-repeat;
  padding: 1px;
  transition: background-size 500ms, background-position 0s 500ms;
}
.email:hover {
  color: var(--info);
  background-image: linear-gradient(var(--info) 0%, var(--info) 100%);
  background-position: 100% 100%;
  background-size: 100% 1px;
}
@media screen and (max-width: 1023px) {
  .filters-wrapper {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 16px;
  }
  .squads-wrapper {
    width: 100%;
    justify-content: center;
  }
}
</style>